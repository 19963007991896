@tailwind base;
@tailwind components;
@tailwind utilities;
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #10142B; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2a3783; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55555500; 
}
body{
  background-image: url('../src/assets/site-bg.jpg');
  background-size: cover;
  
}
.bg-main {
  background-image: url('../src/assets/main-bg.jpg');
  background-position: center;
  background-size: cover;
}
.bg-main2 {
  background-image: url('../src/assets/main2-bg.png');
  background-position: center;
  background-size: cover;
}
.btn {
  background: linear-gradient(
    92.23deg,
    #dabd1d 21.43%,
    #6d5f0e 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(235, 219, 5, 0.51);
}
.btn-discord {
  background: linear-gradient(
    92.23deg,
    #7289da 21.43%,
    #424549 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(235, 219, 5, 0.51);
  border-radius: 9999px;
    font-family: Orbitron;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #6d5f0e 21.43%,
    #dabd1d 117.04%
  );
  transition: 0.5s;
}

.btn-discord:hover {
  background: linear-gradient(
    92.23deg,
    #424549 21.43%,
    #7289da 117.04%
  );
  transition: 0.5s;
  border-radius: 9999px;
    font-family: Orbitron;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #6d5f0e 21.43%,
    #dabd1d 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#dabd1d] to-[#6d5f0e] font-bold font-primary hover:from-[#6d5f0e] hover:to-[#dabd1d];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
